import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import { Header } from 'components/header'
import Main from 'components/main'
import Footer from 'components/footer'
import ProjectHeader from 'components/projectHeader'
import ImageBlock from 'components/imageBlock'
import ImageBlockWithCaption from 'components/imageBlockWithCaption'
import ThreeColImageBlockWithCaption from 'components/threeColImageBlockWithCaption'
import TextBlock from 'components/textBlock'
import ThreeColumn from 'components/threeColumn'
import ProjectFooter from 'components/projectFooter'
import { graphql } from 'gatsby'

const Bones = ({ data, location }) => (
  <Layout location={location}>
    <Helmet>
      <title>{data.bonesJson.metaTitle}</title>
    </Helmet>
    <Header />
    <Main>
      <article>
        <ProjectHeader
          heading={data.bonesJson.heading}
          subheading={data.bonesJson.subheading}
          disciplines={data.bonesJson.disciplines}
        />
        <ImageBlock src={data.bonesJson.projectImages.imageOne} alt={data.bonesJson.metaTitle} />
        <TextBlock copy={data.bonesJson.copy} />
        <ImageBlockWithCaption
          src={data.bonesJson.projectImages.imageTwo}
          alt={data.bonesJson.metaTitle}
          caption="Landing page concept created with Bones"
        />

        <ThreeColumn>
          <ImageBlock
            src={data.bonesJson.projectImages.imageOneMobile}
            alt={data.bonesJson.metaTitle}
            noPadding={true}
          />
          <ImageBlock
            src={data.bonesJson.projectImages.imageTwoMobile}
            alt={data.bonesJson.metaTitle}
            noPadding={true}
          />
          <ImageBlock
            src={data.bonesJson.projectImages.imageThreeMobile}
            alt={data.bonesJson.metaTitle}
            noPadding={true}
          />
        </ThreeColumn>
        <ImageBlock src={data.bonesJson.projectImages.imageThree} alt={data.bonesJson.metaTitle} />
        <ImageBlock src={data.bonesJson.projectImages.imageFive} alt={data.bonesJson.metaTitle} />
        <ImageBlock src={data.bonesJson.projectImages.imageSix} alt={data.bonesJson.metaTitle} />

        <ThreeColImageBlockWithCaption
          imageOneSrc={data.bonesJson.projectImages.imageFourMobile}
          imageOneAlt={data.bonesJson.metaTitle}
          imageTwoSrc={data.bonesJson.projectImages.imageFiveMobile}
          imageTwoAlt={data.bonesJson.metaTitle}
          imageThreeSrc={data.bonesJson.projectImages.imageSixMobile}
          imageThreeAlt={data.bonesJson.metaTitle}
          caption="News blog concept created with Bones"
        />

        <ImageBlock src={data.bonesJson.projectImages.imageFour} alt={data.bonesJson.metaTitle} />

        <ThreeColImageBlockWithCaption
          imageOneSrc={data.bonesJson.projectImages.imageEightMobile}
          imageOneAlt={data.bonesJson.metaTitle}
          imageTwoSrc={data.bonesJson.projectImages.imageSevenMobile}
          imageTwoAlt={data.bonesJson.metaTitle}
          imageThreeSrc={data.bonesJson.projectImages.imageNineMobile}
          imageThreeAlt={data.bonesJson.metaTitle}
          caption="Travel App concept created with Bones"
        />
      </article>
      <ProjectFooter link={data.bonesJson.nextLink} />
    </Main>
    <Footer />
  </Layout>
)

Bones.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default Bones

export const query = graphql`
  query BonesQuery {
    bonesJson {
      metaTitle
      metaDescription
      heading
      subheading
      disciplines
      copy
      nextLink
      projectImages {
        imageOne {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1032, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        imageTwo {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1032, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        imageThree {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1032, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        imageFour {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1032, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        imageFive {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1032, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        imageSix {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1032, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        imageOneMobile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 750, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        imageTwoMobile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 750, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        imageThreeMobile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 750, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        imageFourMobile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 750, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        imageFiveMobile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 750, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        imageSixMobile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 750, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        imageSevenMobile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 750, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        imageEightMobile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 750, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        imageNineMobile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 750, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
      }
    }
  }
`
