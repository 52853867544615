import React from 'react'
import PropTypes from 'prop-types'
import { StyledCaption } from './caption.css'

const Caption = ({ text }) => <StyledCaption>{text}</StyledCaption>

Caption.propTypes = {
  text: PropTypes.string.isRequired
}

export default Caption
