import styled from 'styled-components'
import media from 'constants/breakpoints'
import InViewAnimation from 'components/inViewAnimation'

export const InViewAnimationContainer = styled(InViewAnimation)`
  width: 100%;
  margin-bottom: var(--xl);

  ${media.TABLET`
    margin-bottom: calc(var(--xl) * 2);
  `}
`
