import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Container from 'components/container'
import Caption from 'components/caption'
import { InViewAnimationContainer } from './imageBlockWithCaption.css'

const ImageBlock = ({ src, alt, caption }) => {
  const image = getImage(src)

  return (
    <Container>
      <InViewAnimationContainer>
        <GatsbyImage image={image} alt={alt} />
        <Caption text={caption} />
      </InViewAnimationContainer>
    </Container>
  )
}

ImageBlock.propTypes = {
  src: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired
}

export default ImageBlock
