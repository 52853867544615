import styled from 'styled-components'
// import media from 'constants/breakpoints'

export const StyledCaption = styled.div`
  display: flex;
  justify-content: center;
  margin-top: var(--sm);
  font-size: var(--text-xs);
  font-weight: 300;
  text-align: center;
  color: var(--neutral800);
`
