import styled from 'styled-components'
import media from 'constants/breakpoints'
import InViewAnimation from 'components/inViewAnimation'
import Container from 'components/container'

export const StyledContainer = styled(Container)`
  margin-bottom: var(--xl);

  ${media.TABLET`
    margin-bottom: calc(var(--xl) * 2);
  `}
`

export const FlexContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  > div {
    margin-right: 2.5%;

    &:last-child {
      margin-right: 0;
    }

    &:nth-child(odd) {
      margin-top: 0;
    }
  }

  ${media.TABLET`
  > div {
    margin-right: 5%;

    &:last-child {
      margin-right: 0;
    }

    &:nth-child(odd) {
      margin-top: calc(var(--xl) * 2);
    }
  }
  `}
`

export const InViewAnimationContainer = styled(InViewAnimation)`
  width: 100%;
`
