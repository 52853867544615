import React from 'react'
import PropTypes from 'prop-types'
import Caption from 'components/caption'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { StyledContainer, FlexContainer, InViewAnimationContainer } from './threeColImageBlockWithCaption.css'

const ThreeColImageBlockWithCaption = ({
  imageOneSrc,
  imageOneAlt,
  imageTwoSrc,
  imageTwoAlt,
  imageThreeSrc,
  imageThreeAlt,
  caption
}) => {
  const imageOne = getImage(imageOneSrc)
  const imageTwo = getImage(imageTwoSrc)
  const imageThree = getImage(imageThreeSrc)

  return (
    <StyledContainer>
      <InViewAnimationContainer>
        <FlexContainer>
          <GatsbyImage image={imageOne} alt={imageOneAlt} />
          <GatsbyImage image={imageTwo} alt={imageTwoAlt} />
          <GatsbyImage image={imageThree} alt={imageThreeAlt} />
        </FlexContainer>
        <Caption text={caption} />
      </InViewAnimationContainer>
    </StyledContainer>
  )
}

ThreeColImageBlockWithCaption.propTypes = {
  imageOneSrc: PropTypes.object.isRequired,
  imageOneAlt: PropTypes.string.isRequired,
  imageTwoSrc: PropTypes.object.isRequired,
  imageTwoAlt: PropTypes.string.isRequired,
  imageThreeSrc: PropTypes.object.isRequired,
  imageThreeAlt: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired
}

export default ThreeColImageBlockWithCaption
